@import url( 'https://fonts.googleapis.com/css?family=Raleway:300,400,400i,800');
$primary: #f26d87;
/* MAIN COLOR */
$secondary: #a0d6f4;
/* SECONDARY COLOR */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

body,
html {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-family: 'Raleway', sans-serif;
}

.flash {
    display: none;
}

blockquote,
q {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}
.navbar-default{
  background-color: $primary;
}
nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        > li > a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $wht;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0;
                display: inline-block;
            }

            &:active,
            &:focus {
                background: transparent;
                color: $wht;
                outline: 0;
            }

            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0;
}

.navbar-toggle {
    margin: 20px 15px 8px 0;
    border: 1px solid transparent;
    border-radius: 4px;
}
/** LOGIN FORM **/
@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 1em 2em;
    font-size: 1em;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1.5em 0;
    transition: 0.7s ease all;

    &:hover {
      background: $primary;
      color: $wht;
        text-decoration: none;
        @media (max-width: 1024px) {
          background: $primary;
          color: $wht;
            box-shadow: 0 0 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}
.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;

    .close {
        display: none;
    }
    .modal-content {
        color: $primary;

        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#password,
    input#username {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;

        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0 0 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 100%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-footer,
.modal-header {
    background: $primary;
    color: $wht;
}
.modal-open {
    overflow: auto;
    padding-right: 0 !important;
}
input#username {
    margin-bottom: 20px;
}
/** END LOGIN FORM **/
// section start

section {
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    @media(max-width: 1024px){
      background-attachment: scroll;
    }
    h1 {
        font-size: 2.5em;
        background-color: rgba(255, 255, 255, .5);
        padding: 1em 0;
        text-transform: uppercase;
        font-weight: bold;
        text-shadow: 0px 0px 1px lighten($blk, 65%);
        color: $primary;

        @media(max-width: 991px) {
            font-size: 2em;
        }
        @media(max-width: 767px) {
            font-size: 1.8em;
        }
        @media(max-width: 600px) {
            font-size: 1.5em;
        }
    }

    p {
        font-size: 1.5em;
        background-color: rgba(255, 255, 255, .5);
        padding: 1em 0;
        @media(max-width: 991px) {
            font-size: 1.2em;
        }
        @media(max-width: 767px) {
            font-size: 1em;
        }
    }
}

.section-a {
    padding: 350px 0;
    background-color: $secondary;
    background-image: url('../img/bg1.jpg');
    @media(max-width: 1024px) {
        padding: 250px 0;
    }
    @media(max-width: 767px) {
        padding: 150px 0;
    }
    @media(max-width: 600px) {
        padding: 100px 0;
    }
    @media(max-width: 450px) {
        padding: 50px 0;
    }
    @media(max-width: 350px) {
        padding: 30px 0;
    }
}

.section-b {
    padding: 350px 0;
    background-color: $primary;
    background-image: url('../img/bg2.jpg');

    @media(max-width: 1024px) {
        padding: 250px 0;
    }
    @media(max-width: 767px) {
        padding: 150px 0;
    }
    @media(max-width: 600px) {
        padding: 100px 0;
    }
    @media(max-width: 450px) {
        padding: 80px 0;
    }
    @media(max-width: 350px) {
        padding: 30px 0;
    }
}

.section-c {
    padding: 100px 0;
    background-color: $secondary;
    @media(max-width: 1024px) {
        padding: 80px 0;
    }
    @media(max-width: 767px) {
        padding: 50px 0;
    }
    @media(max-width: 450px) {
        padding: 30px 0;
    }
}

.section-d {
    padding: 350px 0;
    background-color: $primary;
    background-image: url('../img/bg3.jpg');

    @media(max-width: 1024px) {
        padding: 250px 0;
    }
    @media(max-width: 767px) {
        padding: 150px 0;
    }
    @media(max-width: 600px) {
        padding: 100px 0;
    }
    @media(max-width: 450px) {
        padding: 80px 0;
    }
    @media(max-width: 350px) {
        padding: 30px 0;
    }
}
// section end

footer {
    padding: 50px 0 20px;
    background: darken($primary,10%);
    color: $wht;
    font-size: 15px;

    a {
        color: $wht;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }

        &:hover {
            color: lighten($wht, 10%);
            outline: 0 !important;
            text-decoration: none;
        }

        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }

    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#password,
input#username {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:active,
a:focus,
a:hover {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}
@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }

    .navbar-header a {
        float: left;
    }
}
@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }

    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}
